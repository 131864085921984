.formRow {
  &+.formRow {
    margin-top: 16px;
  }

  input,
  textarea {
    display: block;
    caret-color: transparentize(#000, 0.85);
    width: 100%;
    border-bottom: 1px solid transparentize(#000, 0.85);
    padding: 5px 0;
    @include fontSize(16, 24);
    font-family: $baseFontFamily;
    border-radius: 0;

    &:focus::placeholder {
      color: transparent;
    }
  }

  textarea {
    overflow: hidden;
    resize: none;
  }

  &_label {
    color: #BBB;
    @include fontSize(10, 12);

    .lock-icon {
      filter: grayscale(100);
      font-size: 0.75rem;
      height: 1.25rem;
      width: 1.25rem;
    }
  }

  &--security {
    input {
      @include fontSize(56, 56);
      text-transform: uppercase;
      text-align: center;
      width: 80%;
      margin: auto;
    }
  }

  &--checkbox {
    border-bottom: 1px solid #D5D5D5;

    &+.formRow--checkbox {
      margin-top: 8px;
    }

    .formRow_label {
      position: relative;
      display: block;
      color: currentColor;
      @include fontSize(16, 20);
      padding: 5px 0;
    }

    input {
      position: absolute;
      visibility: hidden;
      height: 0;
      width: 0;

      &:checked {
        &+span {
          background-color: $cDark;

          &::after {
            background-color: #FFF;
            left: calc(100% - 14px - 2px);
          }
        }
      }
    }

    span.pretty-toggle {
      position: absolute;
      right: 0;
      display: inline-block;
      width: 48px;
      height: 20px;
      border-radius: 20px;
      background-color: transparent;
      border: 1px solid $cDark;
      transition: background-color 0.2s linear;

      &::after {
        content: "";
        position: absolute;
        top: 2px;
        left: 2px;
        width: 14px;
        height: 14px;
        border-radius: 14px;
        background-color: $cDark;
        transition: background-color 0.2s linear, left 0.6s $ExpoEaseOut;
      }
    }
  }

  &--radio {
    border-bottom: 1px solid #D5D5D5;

    &+.formRow--radio {
      margin-top: 8px;
    }

    .formRow_label {
      position: relative;
      display: block;
      color: currentColor;
      @include fontSize(16, 20);
      padding: 5px 0;
    }

    input {
      position: absolute;
      visibility: hidden;
      height: 0;
      width: 0;

      &:checked {
        &+span {
          background-color: $cDark;

          &::after {
            transform: scale(1);
          }
        }
      }
    }

    span.pretty-radio {
      position: absolute;
      right: 0;
      display: inline-block;
      width: 20px;
      height: 20px;
      border-radius: 20px;
      background-color: transparent;
      border: 1px solid $cDark;
      transition: background-color 0.2s linear;

      &::after {
        content: "";
        position: absolute;
        top: 4px;
        left: 4px;
        width: 10px;
        height: 10px;
        border-radius: 10px;
        background-color: #FFF;
        transform: scale(0);
        transition: transform 0.6s $ExpoEaseOut;
      }
    }
  }
}

.radioButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  label {
    cursor: pointer;
    position: relative;
    width: calc(50% - 12px);
    margin-top: 24px;
    text-align: center;
    
    &.locked {
      pointer-events: none;
    }

    input {
      position: absolute;
      visibility: hidden;
      height: 0;
      width: 0;

      &:checked+span {
        background-color: $cDarker;
        color: #FFF;
      }
    }

    span {
      display: block;
      padding: 8px 16px;
      border: 1px solid $cDarker;
      transition: background-color 0.2s linear, color 0.2s linear, border-color 0.2s linear;

      &.locked {
        color: lighten($cDark, 50%);
        pointer-events: none;

        &::after {
          background: #e1e1e1;
          border-radius: 100px;
          border: 1px solid black;
          bottom: -15px;
          color: $cDark;
          content: 'Coming Soon';
          display: block;
          filter: grayscale(100);
          font-size: 0.75rem;
          left: 50%;
          padding: 0.1rem 0.4rem;
          position: absolute;
          transform: translate(-50%, 0%);
          white-space: nowrap;
        }
      }
    }
  }
}


label.radio-label {
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
  }
}

label.toggle-label {
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
  }
}
