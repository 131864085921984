﻿@mixin fontSize($fontSize, $lineHeight: null, $bfs: $baseFontSize){
    font-size: ($fontSize/strip-unit($bfs)) + rem;
    @if $lineHeight{
        line-height: $lineHeight/$fontSize;
    }@else{
        line-height: $baseLineHeight;
    }
}

@function strip-unit($number){
    @if type-of($number) == 'number' and not unitless($number){
        @return $number / ($number * 0 + 1);
    }

    @return $number;
}

@mixin hover() {
    @media (hover: hover) {
        &:hover{
            @content;
        }
    }
}

@mixin margins($size,$value){
    .mt#{$size}{ margin-top: $value; }
    .mb#{$size}{ margin-bottom: $value; }
    .ml#{$size}{ margin-left: $value; }
    .mr#{$size}{ margin-right: $value; }
}