.button {
  display: inline-block;
  border: 1px solid $cDarker;
  height: 48px;
  border-radius: 48px;
  padding-left: 24px;
  padding-right: 24px;
  @include fontSize(18, 20);
  color: $cDarker;
  background-color: transparent;
  transition: background-color 0.2s linear, color 0.2s linear, border-color 0.2s linear, transform 0.4s $ExpoEaseOut;
  user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;

  &:not([disabled]) {
    &:active {
      transform: scale(0.9);
    }

    &.highlighted {
      background-color: $cDarker;
      color: #FFF;
    }
  }

  &[disabled] {
    opacity: 0.3;
    cursor: not-allowed;
  }

  &--sized {
    min-width: 168px;
  }

  &--filled {
    background-color: $cDarker;
    color: #FFF;
  }

  &--nav {
    line-height: 0.75;
    padding: 0;
    transition: background-color 0.2s linear, color 0.2s linear, border-color 0.2s linear, opacity 0.6s, transform 0.4s $ExpoEaseOut;
    width: 48px;

    &.hidden {
      cursor: default;
      opacity: 0;
    }
  }

  &--square {
    border-radius: 0;
    @include fontSize(16, 24);
  }

  &--sm {
    height: 26px;
    padding-left: 12px;
    padding-right: 12px;
    @include fontSize(12, 14);
  }

  svg {
    fill: currentColor;
  }

  >span {
    &:not(.flex-column, .flex-row) {
      display: inline-block;
    }
    
    // transform: translateY(-2px); // Fix typo line height

    &+svg {
      margin-left: 4px;
    }
  }

  >svg {
    display: inline-block;
    vertical-align: middle;
    // transform: translateY(-2px); // Fix typo line height

    &+span {
      margin-left: 4px;
      // margin-top: -2px; // Fix typo line height
    }
  }
}

.buttonsGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  button {
    position: relative;
    width: calc(50% - 12px);
    margin-top: 24px;
    @include fontSize(16, 22);
    border-radius: 0;
    height: 40px;

    &.locked {
      color: lighten($cDark, 50%);
      pointer-events: none;

      &::after {
        background: #e1e1e1;
        border-radius: 100px;
        border: 1px solid black;
        bottom: -15px;
        color: $cDark;
        content: 'Coming Soon';
        display: block;
        filter: grayscale(100);
        font-size: 0.75rem;
        left: 50%;
        padding: 0.1rem 0.4rem;
        position: absolute;
        transform: translate(-50%, 0%);
        white-space: nowrap;
      }
    }
  }
}
