.navbar {
  backdrop-filter: blur(6px);
  background-color: transparent;
  left: 0;
  margin: 0 auto;
  max-width: 600px;
  padding-left: 16px;
  padding-right: 16px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 40;

  &.shorten {
    height: 80px;
  }

  .edit-page-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
    padding: 0 0 10px 0;
    transition: transform 0.8s $ExpoEaseOut;
    width: $page-section-option-width;

    &.shown {
      transform: translateX(0%);
    }

    &.slid-out {
      transform: translateX(-150%);
    }
    
    button {
      background: #ffffff11;
      font-size: 0.75rem;
      height: 2rem;
      width: min(48%, 20rem);

      &.active {
        background: #1c1c1cf0;
        color: white;
      }
    }
  }

  .main-paralllel-portion {
    align-items: center;
    display: flex;
    height: $navBarHeight;
    justify-content: space-between;

    @media (min-width: 600px) {
      margin-top: 20px;
    }
  }

  .navbar_logo {
    cursor: pointer;
    width: 40px;
    height: 40px;
    background-image: url("https://paralllel-webpage-assets.s3.eu-west-3.amazonaws.com/images/logo.png");
    background-position: center center;
    background-size: 24px 34px;
  }

  .navbar_menuButton {
    position: relative;
    width: 40px;
    height: 41px;
    z-index: 41;

    span {
      position: absolute;
      left: 7px;
      width: 26px;
      height: 1px;
      background-color: $cDark;
      transition: transform 0.8s $ExpoEaseOut, opacity 0.2s linear;
      backface-visibility: hidden;
      will-change: transform, opacity;

      &:nth-child(1) {
        top: 13px;
        transform-origin: center right;
      }

      &:nth-child(2) {
        top: 20px;
        transform-origin: center right;
        transition-delay: 0.05s;
      }

      &:nth-child(3) {
        top: 27px;
        transform: scaleX(0.6);
        transform-origin: center right;
        transition-delay: 0.1s;
      }
    }

    @include hover {
      span {
        &:nth-child(1) {
          transform: scaleX(0.6);
        }

        &:nth-child(2) {
          transform: scaleX(0.7);
        }

        &:nth-child(3) {
          transform: scaleX(1);
        }
      }
    }

    &.opened {
      span {
        &:nth-child(1) {
          transform: scaleX(1) rotate(-45deg) translateY(-4px);
        }

        &:nth-child(2) {
          transform: scaleX(0);
          opacity: 0;
        }

        &:nth-child(3) {
          transform: scaleX(1) rotate(45deg) translateY(3px);
        }
      }
    }
  }
}
