#root {
  height: 100dvh;
}

.app{
    position: relative;
    height: 100dvh;
    max-height: 100dvh;
    max-width: 600px;
    margin: auto;
    overflow-x: hidden;

    @media (min-width: 600px) {
      aspect-ratio: 9/19.5;
      border-radius: 55px;
      font-size: 80%;
      max-height: 100vh;
      max-width: 100vw;
      overflow-y: hidden;
      transform: scale(0.85);

      &::before {
        border: 2px solid black;
        border-radius: 55px;
        content: '';
        position: absolute;
        pointer-events: none;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
      }
    }
}

.flex-column {
  display: flex;
  flex-direction: column;

  &.centered {
    justify-content: center;
  }
}

.flex-row {
  display: flex;
  flex-direction: row;

  &.centered {
    justify-content: center;
  }
}
