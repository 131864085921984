﻿.main {
  display: flex;
  flex-direction: column;
  padding-top: $navBarHeight;
  height: 100%;

  .page {
    display: flex;
    flex: 1;

    &_content {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 32px;

      &--middle {
        justify-content: center;
      }
    }

    &_actions {
      text-align: center;
      padding: 32px 32px;

      .button {
        margin-top: 16px;
      }
    }
  }
}
