.menu{
  background-color: $video-background-color;
  overflow: hidden;
  padding: 100px 32px;
  position: absolute;
  top: 0; left: 0; right: 0; bottom: 0;
  transform: translateX(101%);
  transition: transform 0.8s $ExpoEaseOut;
  will-change: transform;
  z-index: 30;

  &.visible{
    transform: translateX(0);
  }

  .menu_mainNav{
    @include fontSize(32,40);
    font-weight: 600;
    user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    
    ul{
      li{
        & + li {
          margin-top: 8px;
        }
        
        a {
          display: block;
          padding: 8px 0;
          text-decoration: none;
        }
      }
    }
  }
}

.submenu{
  position: absolute;
  z-index: 10;
  top: 0; left: 0; right: 0; bottom: 0;
  // REFERENCE LATER
  // background-color: #F4F4F4;
  background-color: $video-background-color;
  padding: 100px 32px 0px 32px;
  overflow: auto;
  overflow-y: hidden;
  transform: translateX(101%);
  transition: transform 0.8s $ExpoEaseOut;
  will-change: transform;

  &.visible{
    transform: translateX(0);
  }

  &_close{
    position: absolute;
    top: 108px;
    height: 30px;
    right: 24px;
    @include fontSize(14,14);
  }

  &_title{
    @include fontSize(36,42);
    font-weight: 600;
    margin-bottom: 32px;
  }

  &_actions{
    position: fixed;
    z-index: 5;
    left: 0; right: 0; bottom: 0;
    padding: 0 32px 16px 32px;
    text-align: center;
  }
}

.projectsList {
  display: flex;
  height: 100%;
  margin-left: -32px;
  margin-right: -32px;
  overflow-x: scroll;
  padding: 0 32px 32px 32px;
  white-space: nowrap;

  &::-webkit-scrollbar {
    height: 0rem;
    width: 0rem;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  #load-more-projects-button {
    margin: 50% auto auto 24px;
  }
}

.projectItem {
  $project-item-scale-factor: 0.4;

  aspect-ratio: 9/19.5;
  position: relative;
  height: calc((min(100vh, $iPhone15Height) * $project-item-scale-factor) + 50px);
  min-width: calc(min(100vw, $iPhone15Width) * $project-item-scale-factor);

  .iframe-aligner {
    display: flex;
    flex-direction: row;
    height: calc(min(100vh, $iPhone15Height) * $project-item-scale-factor);
    justify-content: center;
    
    iframe {
      height: min(100vh, $iPhone15Height);
      min-width: min(100vw, $iPhone15Width);
      transform: scale($project-item-scale-factor);
      transform-origin: top center;
    }
  }

  & + .projectItem{
    margin-left: 24px;
  }

  &_preview{
    position: relative;
    background-color: #888;
    box-shadow: 0 4px 8px transparentize(#000, 0.5);

    &::before{
      content: "";
      display: block;
      padding-top: 156%;
    }
  }

  &_title{
    margin: 16px 0 8px 0;
  }

  &_actions{
    display: flex;
    justify-content: space-between;

    .button{
      width: calc(50% - 4px);
    }
  }
}
