﻿$cDarker: #1C1C1C;
$cDark: #2F2F2F;

$video-background-color: #e1e1e1;

//  BASE
//////////////////////////////////////////////
$baseFontFamily: "Roobert", Helvetica, sans-serif;
$baseFontSize: 16px;
$baseLineHeight: 1.3;
$baseLetterSpacing: 0;
// $baseBackgroundColor: #fff;
$baseBackgroundColor: $video-background-color;
$baseTextColor: $cDark;
$navBarHeight: 60px;

$selectionBackgroundColor: #999;
$selectionColor: #232323;


// SPACERS
///////////////////////////////////////////////////////////
$sp0: 0;
$sp1: 4px;
$sp2: 8px;
$sp3: 12px;
$sp4: 16px;
$sp5: 24px;
$sp6: 32px;


//  EASES
///////////////////////////////////////////////////////////
$easeOutQuint: cubic-bezier(0.23, 1, 0.32, 1);
$easeOutCirc: cubic-bezier(0.075, 0.82, 0.165, 1);
$easeOutExpo: cubic-bezier(0.19, 1, 0.22, 1);
$easeInOutExpo: cubic-bezier(0.8, 0, 0.2, 1);

// GSAP EASINGS
$Power0EaseNone: cubic-bezier(0.250, 0.250, 0.750, 0.750);
$Power1EaseIn: cubic-bezier(0.550, 0.085, 0.680, 0.530);
$Power1EaseOut: cubic-bezier(0.250, 0.460, 0.450, 0.940);
$Power1EaseInOut: cubic-bezier(0.455, 0.030, 0.515, 0.955);
$Power2EaseIn: cubic-bezier(0.550, 0.055, 0.675, 0.190);
$Power2EaseOut: cubic-bezier(0.215, 0.610, 0.355, 1.000);
$Power2EaseInOut: cubic-bezier(0.645, 0.045, 0.355, 1.000);
$Power3EaseIn: cubic-bezier(0.895, 0.030, 0.685, 0.220);
$Power3EaseOut: cubic-bezier(0.165, 0.840, 0.440, 1.000);
$Power3EaseInOut: cubic-bezier(0.770, 0.000, 0.175, 1.000);
$Power4EaseIn: cubic-bezier(0.755, 0.050, 0.855, 0.060);
$Power4EaseOut: cubic-bezier(0.230, 1.000, 0.320, 1.000);
$Power4EaseInOut: cubic-bezier(0.860, 0.000, 0.070, 1.000);
$CircEaseIn: cubic-bezier(0.600, 0.040, 0.980, 0.335);
$CircEaseOut: cubic-bezier(0.075, 0.820, 0.165, 1.000); // wip
$CircEaseInOut: cubic-bezier(0.785, 0.135, 0.150, 0.860); // wip
$ExpoEaseIn: cubic-bezier(0.950, 0.050, 0.795, 0.035);
$ExpoEaseOut: cubic-bezier(0.190, 1.000, 0.220, 1.000);
$ExpoEaseInOut: cubic-bezier(1.000, 0.000, 0.000, 1.000);
$SineEaseIn: cubic-bezier(0.470, 0.000, 0.745, 0.715);
$SineEaseOut: cubic-bezier(0.390, 0.575, 0.565, 1.000);
$SineEaseInOut: cubic-bezier(0.445, 0.050, 0.550, 0.950);


$iPhone15Height: 852px;
$iPhone15Width: 393px;

$page-option-scale-factor: 0.6;
$page-section-option-width: calc($iPhone15Width * $page-option-scale-factor);
