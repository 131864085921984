.btnprev,
.btnnext {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 10;
  width: 50%;
  text-align: center;
}

.btnnext {
  left: 50%;
}


.formNav {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  // REFERENCE LATER
  // opacity: 0;
  transition: opacity 0.2s linear;

  &.hidden {
    opacity: 0;
  }

  &_slider {
    padding-top: 30px;
    -webkit-mask: linear-gradient(90deg, transparent, #000, transparent);
    mask: linear-gradient(90deg, transparent, #000, transparent);
    overflow: hidden;
    width: 80%;
    height: 100%;
  }

  ul {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    margin-left: calc(50% - 25px);
    transition: transform 0.8s $CircEaseInOut;

    li {
      position: relative;
      @include fontSize(12, 16);
      font-weight: 600;
      min-width: 50px;
      max-width: 50px;
      text-align: center;
      opacity: 0.5;
      transition: opacity 0.2s linear;

      &::after {
        content: "";
        position: absolute;
        left: calc(50% - 3px);
        top: calc(50% + 15px);
        width: 6px;
        height: 6px;
        background-color: #000;
        border-radius: 50%;
        transform: scale(0);
        transition: transform 0.6s $CircEaseInOut;
      }

      &.formNav_step--first,
      &.formNav_step--last {
        &::after {
          display: none;
        }
      }

      &.active {
        opacity: 1;

        &::after {
          transform: scale(1);
        }
      }
    }
  }

  @for $i from 0 through 7 {
    &[data-step="#{$i}"] ul {
      transform: translate($i * 50px * -1);
    }
  }
}

.formSteps {
  position: relative;
  flex: 1;
  height: calc(100% - 60px - 60px - 80px);
  overflow: hidden;

  &_slider {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 100%;
    transition: transform 0.6s $CircEaseInOut;
  }

  .step {
    display: flex;
    flex-direction: column;
    min-width: clamp(100px, 100%, 600px);
    max-width: clamp(100px, 100%, 600px);
    width: clamp(100px, 100%, 600px);
    padding: 0 32px;

    &_title {
      display: flex;
      flex: 1;
      align-items: center;
      opacity: 0;
      transition: opacity 0.2s ease-out;

      .word {
        display: inline-block;
        margin-right: 12px;
        overflow: hidden;

        .char {
          display: inline-block;
          line-height: 1.2;
          transform: translateY(101%);
          transition: all 0.8s ease-in-out;

          &.shown {
            transform: translateY(0%);
          }
        }
      }
    }

    
    &.step--active {
      .step_title {
        opacity: 1;

        &.field-focused {
          opacity: 0;
        }
      }
    }

    &_content {
      padding: 16px 0 32px 0;
      opacity: 0;
      transition: opacity 0.2s;

      &.active {
        opacity: 1;
      }
    }

    &--last {
      .step_content {
        position: relative;
        flex: 1;
      }
    }
  }

  @for $i from 0 through 7 {
    &[data-step="#{$i}"] .formSteps_slider {  transform: translate($i * -100%); }
  }
}

.generationCircle {
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 1;
  transform: translate(-50%, -50%);
  transition: 0.25s;
  width: clamp(200px, 100%, 600px);
  margin-top: -20px;

  &::before {
    content: "";
    display: block;
    padding-top: 100%;
  }

  &_value {
    position: absolute;
    bottom: calc(100% + 4px);
    @include fontSize(14, 16);
    font-weight: 600;
    text-align: center;
    width: 100%;
  }

  &_progress {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transform: rotate(-90deg);

    padding: 0.5rem;
  }

  &.form-submitted {
    opacity: 0;
  }
}

.formActions {
  height: 80px;
  padding: 0 0 32px 0;
  text-align: center;

  .button {
    &+.button {
      margin-left: 24px;
    }
  }
}
