@font-face {
    font-family: 'Roobert';
    src: url(../../fonts/Roobert-Regular.woff2) format('woff2'),
        url(../../fonts/Roobert-Regular.woff) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'Roobert';
    src: url(../../fonts/Roobert-SemiBold.woff2) format('woff2'),
        url(../../fonts/Roobert-SemiBold.woff) format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: fallback;
}
